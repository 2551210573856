export default [
  {
    key: "branchCode",
    label: "Site ID",
    thStyle:{color:"white", textAlign: "center"}
  },
  { key: "branchName", label: "Site Name", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "ticketNo", label: "Job No", sortable: true ,thStyle:{color:"white",width: "8% !important", textAlign: "center"}},
  { key: "openTicketDate", label: "Open Date", sortable: true , thStyle:{color:"white", textAlign: "center"}},
  { key: "problemTypeName", label: "Problem type", sortable: false , thStyle:{color:"white", textAlign: "center"}},
  {
    key: "problemDetail",
    label: "Problem",
    sortable: false,
    thStyle: { width: "30% !important", textAlign: "center" ,color:"white"},
  },
  { key: "assignTo", label: "Resolve by", sortable: false ,thStyle:{color:"white", textAlign: "center", width: "7% !important"}},
  {
    key: "remark",
    label: "Remark",
    sortable: false,
    thStyle: { width: "15% !important", textAlign: "center", color:"white" },
  },
];
