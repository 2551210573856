<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="6" class="my-1">
            <h2>Inprogress (ใบงานทั้งหมด {{ showNumberItem }} ใบงาน)</h2>
          </b-col>
          <b-col md="6" class="my-1">
            <b-form
              v-on:submit.prevent
              inline
              class="d-flex justify-content-md-end align-items-center"
            >
              <b-input-group class="input-group-merge flex-grow-1 mb-2 mb-md-0">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>
              <div class="d-flex flex-wrap">
                <b-button
                  v-if="exportPermission == 'view'"
                  variant="outline-secondary"
                  class="ml-1 shadow"
                >
                  <export-excel
                    :data="items"
                    :fields="json_fields"
                    worksheet="My Worksheet"
                    name="All Inprogress Tickets.xls"
                  >
                    Export <feather-icon icon="FileTextIcon" size="14" />
                  </export-excel>
                </b-button>

                <b-button
                  v-if="
                    ticketPermission == 'create' || ticketPermission == 'edit'
                  "
                  variant="primary"
                  class="ml-1 shadow"
                  @click="$router.push('/ticket/create')"
                >
                  เปิดใบงาน <feather-icon icon="PlusCircleIcon" size="14" />
                </b-button>
              </div>
            </b-form>
          </b-col>

          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow text-center"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              selectable
              select-mode="single"
              @row-selected="onSelectTickett"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(selected)="{ rowSelected }"></template>
              <template #cell(openTicketDate)="row">
                {{ showDate(row.item.openTicketDate, "original") }}
              </template>
            </b-table>
          </b-col>
          <b-col cols="6" md="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6"  md="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner,
} from "bootstrap-vue";

import Field from "./field";
import FieldExport from "./field/export";
import TicketService from "@/services/ticket";
import commaNumber from "comma-number";

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      fields: Field,
      items: [],
      isLoading: false,
      json_fields: {},
    };
  },
  computed: {
    ticketPermission() {
      const permission = this.$store.getters["user/getPermissionPage"]("TK001");
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    exportPermission() {
      return this.$store.getters["user/getPermissionPage"]("TK002");
    },
    showNumberItem() {
      return commaNumber(this.items.length);
    },
  },

  methods: {
    async getTicketLists() {
      try {
        this.isLoading = true;
        const result = await TicketService.GetTicketList({});
        this.items = result.data.data;
        this.totalRows = this.items.length;
        this.isLoading = false;
      } catch (error) {
        alert("Load data error.");
      }
    },

    onSelectTickett(item) {
      this.$router.push(`/ticket/edit/${item[0].ticketId}`);
    },

    showDate(date, type = "") {
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if (type == "original") {
        return moment(date).subtract(7, "h").format("DD-MM-yyyy HH:mm:ss");
      }

      if (type == "subtract") {
        return moment(date).subtract(7, "h");
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },
    buildFieldExcel() {
      let obj = {};
      FieldExport.forEach((f) => {
        if (f.key == "openTicketDate") {
          obj[f.label] = {
            field: f.key,
            callback: (value) => {
              return this.showDate(value, "original");
            },
          };
        } else {
          obj[f.label] = f.key;
        }
      });

      this.json_fields = obj;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    this.getTicketLists();
    this.buildFieldExcel();
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
