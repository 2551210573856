export default [
  {
    key: "branchCode",
    label: "Site ID",
    thStyle:{color:"white"}
  },
  { key: "branchName", label: "Site Name", sortable: true , thStyle:{color:"white"}},
  { key: "ticketNo", label: "Job No", sortable: true ,thStyle:{color:"white",width: "8% !important"}},
  { key: "openTicketDate", label: "Open Date", sortable: true , thStyle:{color:"white"}},
  { key: "closeTicketDate", label: "Finish Date", sortable: true , thStyle:{color:"white"}},
  { key: "problemTypeName", label: "Problem type", sortable: false , thStyle:{color:"white"}},
  {
    key: "problemDetail",
    label: "Problem",
    sortable: false,
    thStyle: { width: "30% !important", textAlign: "center" ,color:"white"},
  },
  {
    key: "problemSolving",
    label: "Detail",
    sortable: false,
    thStyle: { width: "30% !important", textAlign: "center" ,color:"white"},
  },
  { key: "assignTo", label: "Resolve By", sortable: false ,thStyle:{color:"white"}},
  { key: "slaStatus", label: "SLA", sortable: false ,thStyle:{color:"white"}},
  {
    key: "remark",
    label: "Remark",
    sortable: false,
    thStyle: { width: "15% !important", textAlign: "center", color:"white" },
  },
];
